<template>
  <div class="referral">
    <div class="referral-container">
      <div class="referral-content">
        <div class="referral-title">{{ $t('Referral') }}</div>
        <div class="referral-text">
          {{ $t('Referral your friend and get') }} <span>{{ $t('10% referral bonuses') }}</span> {{ $t('when your friend buy the boxes! 10 boxes bought to unlock the referral program.') }}
          <div class="br"></div>{{ $t('The referral bonuses will be transferred directly to your address with every by box. It cost') }} <span>{{ $t('1 HT to create the referral link') }}</span>.
          <div class="br"></div>{{ $t('When you have successfully created the referral link, please') }} <span>{{ $t('do not clear the browser cache.') }}</span> {{ $t('Otherwise, the referral link will be become invalid.') }}
        </div>
				<div class="referral-img" v-if="is_mobile">
					<img src="@/assets/referral_img.png" alt="">
				</div>
        <input class="referral-input" type="text" :placeholder="$t('Your referral link here')" v-model="invitation_address" disabled>
        <v-btn
            :style="btn_styles"
            color="#EB6930"
            large
            rounded
						:loading="btn_loading"
						@click="_beInviter"
						v-if="!is_inviter"
						:block="is_mobile"
        >{{ $t('CREATE') }}</v-btn>
				<v-btn
						:style="btn_styles"
						color="#EB6930"
						large
						rounded
						:block="is_mobile"
						v-else
						v-clipboard="invitation_address"
						v-clipboard:success="clipboardSuccessHandler"
						v-clipboard:error="clipboardErrorHandler"
				>{{ $t('COPY') }}</v-btn>
      </div>
      <div class="referral-img" v-if="!is_mobile">
        <img src="@/assets/referral_img.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isInviter, beInviter } from '@/utils/cardFactory'
import eventBus from '@/utils/eventBus'

export default {
	name: 'Referral',
	data () {
		return {
			is_inviter: false,
			btn_loading: false,
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		invitation_address () {
			return this.is_inviter ? `${window.location.origin}/#/app/Game/BuyBox?invitation=${this.ADDRESS}` : ''
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
		btn_styles () {
			if (this.is_mobile) {
				return {
					height: '44px !important',
				}
			}
			return {
				height: '60px !important',
				width: '174px',
			}
		},
	},
	created () {
		if (this.ADDRESS) {
			this._isInviter()
		}

		eventBus.$on('connect-event', (res) => {
			this._isInviter()
		})
	},
	methods: {
		async _isInviter () {
			this.is_inviter = await isInviter(this.ADDRESS)
		},
		async _beInviter () {
			this.btn_loading = true
			try {
				await beInviter(this.ADDRESS)
				this.is_inviter = true
				this.btn_loading = false
			} catch (err) {
				this.btn_loading = false
			}
		},
		clipboardSuccessHandler ({ value, event }) {
			console.log('success', value)
			this.$toast.success(this.$t('Replication success'))
		},
		clipboardErrorHandler ({ value, event }) {
			console.log('error', value)
			this.$toast.error(this.$t('Copy failure'))
		},
	},
}
</script>

<style lang="scss" scoped>
@media (max-width: 960px) {
	.referral {
		.br {
			height: 20px;
		}
		.referral-input {
			display: block;
			width: 100%;
			height: 38px;
			background: #495058;
			border-radius: 19px;

			font-weight: 500;
			font-size: 12px;
			line-height: 28px;
			color: #7E8C98;
			padding: 0 20px;
			margin: 20px 0 20px;
		}
		.referral-container {
			display: flex;
			padding: 25px;
		}
		.referral-img {
			img {
				width: 125px;
			}
			text-align: right;
			padding-top: 20px;
		}
	}
}

@media (min-width: 960px) {
	.referral {
		.br {
			height: 40px;
		}
		.referral-input {
			display: block;
			width: 100%;
			max-width: 548px;
			height: 60px;
			background: #495058;
			border-radius: 20px;

			font-weight: 500;
			font-size: 14px;
			line-height: 28px;
			color: #7E8C98;
			padding: 0 20px;
			margin: 60px 0 40px;
		}
		.referral-container {
			display: flex;
			padding: 0 130px;
		}
		.referral-img {
			img {
				width: 100%;
			}

			max-width: 311px;
			padding: 140px 0 0 80px;
		}
		padding: 80px 0;
	}
}

.referral {
  .referral-text {
    span {
      color: #EB6930;
    }

    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #7E8C98;

  }

  .referral-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #E8E8E8;
    margin-bottom: 6px;
  }
}
</style>
